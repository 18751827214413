.profile-no-card {
	margin-top: 9px;
	margin-bottom: 30px;
	
	&__title {
		margin-top: 28px;
		margin-bottom: 13px;
		font-size: 18px;
		font-weight: 400;
	}
	
	img {
		margin: 0 auto;
		width: 315px;
		max-width: 100%;
		height: 199px;
		display: block;
		border-radius: 10px;
		filter: grayscale(1) drop-shadow(0 8px 24px rgba(0, 0, 0, .08));
	}
	
	.profile__notify {
		margin-top: 58px;
	}
}
